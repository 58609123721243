body {
    -ms-overflow-style: none;
}
* {
    font-variant-ligatures: none;
}
::-webkit-scrollbar {
    display: none;
}

@font-face {
font-family: '__pretendard_fde3a9';
src: url(/_next/static/media/ff840cfebfb63b0c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 45 920;
}@font-face {font-family: '__pretendard_Fallback_fde3a9';src: local("Arial");ascent-override: 93.76%;descent-override: 23.75%;line-gap-override: 0.00%;size-adjust: 101.55%
}.__className_fde3a9 {font-family: '__pretendard_fde3a9', '__pretendard_Fallback_fde3a9'
}.__variable_fde3a9 {--font-pretendard: '__pretendard_fde3a9', '__pretendard_Fallback_fde3a9'
}

